import React, { useState, useRef } from "react";
import {
  LayoutGrid,
  FileDown,
  User2,
  Settings,
} from "lucide-react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const Response = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const location = useLocation();
  const { editedResume } = location.state || {};

  // Function to process the resume text
  const processResumeText = (text) => {
    if (!text) return "";

    // Remove * and # characters
    let cleanText = text.replace(/[*#]/g, "");

    // Split the text into lines
    const lines = cleanText.split('\n');

    // Render each line without any special formatting
    const formattedLines = lines.map((line, index) => {
      // Trim the line
      const trimmedLine = line.trim();

      // Skip empty lines
      if (trimmedLine === '') {
        return <br key={index} />;
      }

      // Return the line as is
      return (
        <p
          key={index}
          style={{ margin: '0.5rem 0' }}
        >
          {trimmedLine}
        </p>
      );
    });

    return formattedLines;
  };

  const styles = {
    container: {
      display: "flex",
      minHeight: "100vh",
      fontFamily: "'Inter', sans-serif",
    },
    sidebar: {
      width: "240px",
      backgroundColor: "black",
      color: "white",
      padding: "1.5rem",
    },
    logo: {
      fontSize: "1.25rem",
      fontWeight: "600",
      marginBottom: "2rem",
    },
    nav: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
    navItem: {
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
      color: "#666",
      textDecoration: "none",
      fontSize: "0.875rem",
    },
    activeNavItem: {
      color: "white",
    },
    main: {
      flex: 1,
      padding: "2rem",
      backgroundColor: "white",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: "600",
      marginBottom: "2rem",
      color: "black",
    },
    textBox: {
      width: "80%",
      height: "70vh", // 20% of viewport height
      padding: "1rem",
      fontSize: "1rem",
      fontFamily: "'Inter', sans-serif",
      lineHeight: "1.5",
      border: "1px solid #ccc",
      borderRadius: "4px",
      overflowY: "auto",
      whiteSpace: "pre-wrap",
      backgroundColor: "#f9f9f9",
    },
  };

  return (
    <div style={styles.container}>
      <aside style={styles.sidebar}>
        <div style={styles.logo}>PepperUni</div>
        <nav style={styles.nav}>
          <a href="/" style={{ ...styles.navItem, ...styles.activeNavItem }}>
            <span style={{ fontSize: "1.25rem" }}>⊞</span>
            Home
          </a>
          <a style={styles.navItem}>
            <span style={{ fontSize: "1.25rem" }}>
              <FileDown />
            </span>
            Resume
          </a>
          <a style={styles.navItem}>
            <span style={{ fontSize: "1.25rem" }}>
              <User2 />
            </span>
            Profile
          </a>
          <a style={styles.navItem}>
            <span style={{ fontSize: "1.25rem" }}>
              <Settings />
            </span>
            Setting
          </a>
        </nav>
      </aside>
      <main style={styles.main}>
        <h1 style={styles.title}>Edited Resume</h1>
        {editedResume ? (
          <div style={styles.textBox}>
            {processResumeText(editedResume)}
          </div>
        ) : (
          <p>No resume available.</p>
        )}
      </main>
    </div>
  );
};

export default Response;
